import React from 'react'
import { PageProps } from 'gatsby'
import useSharesPage from '../../utils/use-shares-page'
import Layout from '../../layouts/index'
import { SEO } from '../../components/seo'
import { EurolandIframe } from '../../components/euroland'

export default function ShareSeries(props: PageProps) {
  const sharesPage = useSharesPage()
  
  return (
    <Layout
      location={props.location}
      tabs="shareInfo"
      heros={sharesPage.hero}
      herosFallbackTitle={sharesPage.title}
      herosFallbackSecondaryTitle="Share Price Look Up"
      showStock
    >
      <div className="container flex flex-col mx-auto mt-8 mb-20 lg:mt-4">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <EurolandIframe
              lightUrl="https://tools.eurolandir.com/tools/splookup/?companycode=us-umg&v=light&lang=en-GB"
              darkUrl="https://tools.eurolandir.com/tools/splookup/?companycode=us-umg&v=dark&lang=en-GB"
              adjustHeight
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <SEO />
)
